<template>
  <!-- 已报名竞价 -->
  <div class="subPage" id="registeredBidding">
    <el-col :span="24" class="selectHeadBox">
      <el-row class="selectHead">
        <el-col :span="2"><p class="headText">商品分类</p></el-col>
        <el-col :span="6">
          <el-cascader
            class="width-100"
            v-model="selectCategoryOptions"
            :options="categoryOptions"
            :props="categoryProps"
            clearable
          ></el-cascader>
        </el-col>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="竞拍号" min-width="65">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="挂单方式">
          <template slot-scope="scope">{{
            scope.row.hangResourcesType | typeFormate
          }}</template>
        </el-table-column>
        <el-table-column prop="className" label="商品分类" min-width="70">
        </el-table-column>
        <el-table-column prop="hangWeight" label="重量" min-width="60">
          <template slot-scope="scope"
            >{{ scope.row.hangWeight }}{{ scope.row.unit }}</template
          >
        </el-table-column>
        <!-- <el-table-column
            prop="quantity"
            label="数量"
            min-width="50">
          </el-table-column> -->
        <el-table-column prop="startPrice" label="起拍总价(元)" width="105px">
        </el-table-column>
        <el-table-column prop="name" label="交收仓库"> </el-table-column>
        <el-table-column label="当前价(元)">
          <template slot-scope="scope">{{ scope.row.offerPrice }}</template>
        </el-table-column>
        <el-table-column prop="isFlow" label="是否流拍">
          <template slot-scope="scope">
            <span>{{ scope.row.isFlow | biddingIsFlowFormate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="70">
          <template slot-scope="scope">{{ scope.row.styles }}</template>
        </el-table-column>
        <el-table-column label="竞拍时间" width="180">
          <template slot-scope="scope">
            <p>{{ scope.row.startTime | DateFormate }} 至</p>
            <p>{{ scope.row.endTime | DateFormate }}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="right.bidding && scope.row.styles == '竞拍中'"
              @click.native.prevent="deleteRow(scope.$index, scope.row)"
              type="waring"
              size="small"
            >
              竞价
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </div>
    </el-col>
    <add-registered-bidding
      ref="addDialog"
      @reLoad="getDatas()"
    ></add-registered-bidding>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import addRegisteredBidding from "./addRegisteredBidding.vue";
export default {
  components: {
    "add-registered-bidding": addRegisteredBidding
  },
  data() {
    return {
      activeIndex: "1",
      selectActiveIndex: "1",
      isNewOrder: false, // 新增求购单按钮状态,
      isdeleatOrders: false, // 批量删除按钮状态
      tableData: [], // http://114.116.96.237:38808/apigateway/precacheservice/doc.html#/default/capacity-controlller/queryBiddingBuyerResourceUsingPOST
      multipleSelection: [],
      selectAllTableData: false,
      //分页
      current: 1,
      pageSize: 10,
      totalPage: null,
      // 品种
      categoryOptions: [],
      categoryProps: {
        label: "className",
        value: "classId",
        checkStrictly: false
      },
      selectCategoryOptions: [],
      // ws
      allOrderList: [],
      orderList: [],
      myOrderList: [],
      getpaiArr: [],
      unsubscribeArr: [],
      right: {
        bidding: false
      }
    };
  },
  watch: {
    selectCategoryOptions(val, oldVal) {
      this.current = 1;
      this.pageSize = 10;
      this.totalPage = null;
      this.getDatas();
    }
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getCommodityCategory();
    this.getDatas();
    this.getRights();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.bidding = this.isHasRight("tc-trade-auction");
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    unsubscribe() {
      //取消订阅
      const arrrr = {
        fid: "unsubscribe-req",
        planIds: this.unsubscribeArr
      };
      hqWs.send(arrrr);
      this.getpaiArr = [];
    },
    getpai() {
      if (this.getpaiArr.length > 0) {
        this.unsubscribe();
      }
      for (const i in this.tableData) {
        if (this.tableData.hasOwnProperty(i)) {
          const element = this.tableData[i];
          this.getpaiArr.push(element.orderId);
        }
      }
      this.unsubscribeArr = this.getpaiArr;
      const subscribe = {
        fid: "subscribe-req",
        planIds: this.getpaiArr
      };
      hqWs.send(subscribe);
      hqWs.register(this.DispathSocketEvent);
    },
    DispathSocketEvent(eventData) {
      switch (eventData.fid) {
        case "subscribe-resp": // 订阅成功
          break;
        case "lastprice-push": // 推送竞拍价格变动信息
          if (eventData.respList) {
            const data = eventData.respList;
            if (data.length === 1) {
              this.allOrderList.unshift(data[0]);
            } else {
              this.allOrderList = data;
            }
          } else {
            this.allOrderList = [];
          }
          if (this.allOrderList.length > 10) {
            this.orderList = this.allOrderList.slice(0, 10);
          } else {
            this.orderList = this.allOrderList;
          }
          const myArr = [];
          for (let i = 0; i < this.allOrderList.length; i++) {
            const element = this.allOrderList[i];
            if (
              Number(element.firmId) === Number(this.sessionInfoGetter.firmId)
            ) {
              myArr.push(element);
            }
          }
          if (myArr.length > 10) {
            this.myOrderList = myArr.slice(0, 10);
          } else {
            this.myOrderList = myArr;
          }

          let newPrice = [];
          for (let i = 0; i < this.allOrderList.length; i++) {
            const testPrice = this.allOrderList[i];
            let isHave = false;
            for (let j = 0; j < newPrice.length; j++) {
              const havePrice = newPrice[j];
              if (testPrice.planId == havePrice.planId) {
                isHave = true;
              }
            }
            if (!isHave) {
              newPrice.push(testPrice);
            }
          }
          console.log("newPrice", newPrice);
          // 修改当前价展示
          for (let i = 0; i < newPrice.length; i++) {
            const orderList = newPrice[i];
            for (let j = 0; j < this.tableData.length; j++) {
              const tabledataItem = this.tableData[j];
              if (orderList.planId == tabledataItem.orderId) {
                let newData = this.tableData[j];
                newData.offerPrice = orderList.nowPrice;
                this.$set(this.tableData, j, newData);
              }
            }
          }
          break;
        default:
          break;
      }
    },
    getDatas() {
      protocolFwd.param_queryAuctionEnrolment.param = {
        isFinish: null,
        // orderStatus: null,
        // payStatus: null,
        hangResourceType: ["Q"], //订单类型X：现货挂单 B：履约订金挂单Q：求购单
        page: this.current - 1,
        size: this.pageSize,
        sort: [{ property: "id", direction: "DESC" }],
        firmId: this.sessionInfoGetter.firmId,
        commodityId:
          this.selectCategoryOptions.length > 0
            ? this.selectCategoryOptions[this.selectCategoryOptions.length - 1]
            : null
      };
      http.postFront(protocolFwd.param_queryAuctionEnrolment).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          let arr = [];
          let nowTime = Number(new Date());
          for (let i = 0; i < value.content.length; i++) {
            let element = value.content[i];
            let startTime = element.startTime ? Number(element.startTime) : 0;
            let endTime = element.endTime ? Number(element.endTime) : 0;
            let showTime = element.showTime ? Number(element.showTime) : 0;
            if (element.isFinish == 1) {
              element["styles"] = "已结束";
            } else if (nowTime < showTime) {
              element["styles"] = "未开始";
            } else if (nowTime >= showTime && nowTime < startTime) {
              element["styles"] = "公示中";
            } else if (nowTime >= startTime && nowTime < endTime) {
              element["styles"] = "竞拍中";
            } else if (nowTime >= endTime) {
              element["styles"] = "已结束";
            }
            arr.push(element);
          }
          this.tableData = arr;
          this.totalPage = value.totalElements;

          this.getpai();
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 商品品种+品名
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          let categoryArr = value;
          this.categoryOptions = this.clearChildren(categoryArr);
        } else {
          this.categoryOptions = [];
          this.$EL_MESSAGE(message);
        }
      });
    },
    clearChildren(Arr) {
      Arr.forEach(element => {
        if (element && element.children) {
          if (element.children.length == 0) {
            delete element["children"];
          } else {
            this.clearChildren(element.children);
          }
        }
      });
      return Arr;
    },
    // table 选中调用方法
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val);
    },
    // 操作
    deleteRow(index, row) {
      console.log(index, row);
      if (row.orderStatus == "U") {
        this.$EL_MESSAGE("挂单方已撤单，该竞价单无效!");
        return;
      }
      this.$store.commit("setAuctionEnrolment", row);
      this.$refs.addDialog.showDialog();
    }
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.selectHeadBox {
  margin: 10px 0;
  /deep/ .el-table thead th {
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox {
    line-height: 30px;
    margin-left: 20px;
  }
}
.headText {
  line-height: 40px;
  text-align: center;
}
.pagination {
  margin-top: 15px;
}
.selectHead {
  margin-bottom: 10px;
}
</style>
