<template>
<div>
<!-- 新增竞价求购 -->
  <el-dialog
  v-dialogDrag
    title="竞价详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div>
      <el-row :gutter="20">
        <el-col :span="12">
          <span>竞价编号:{{auctionEnrolmentDetails.auctionCode}}</span>
        </el-col>
        <el-col :span="12">
          <span>报名人数:{{auctionEnrolmentDetails.ennum}}人</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-botttom:30px">
        <!-- <el-col :span="1">
          <i class="el-icon-alarm-clock" style="font-size:20px;color:black"></i>
        </el-col> -->
        <el-col :span="6">
          <span>距离结束:{{day}} 天{{hour}} 时{{min}} 分{{second}} 秒</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span>商品分类</span>
        </el-col>
        <el-col :span="4">
          <span>仓库</span>
        </el-col>
        <el-col :span="3">
          <span>数量</span>
        </el-col>
        <el-col :span="3">
          <span>重量</span>
        </el-col>
        <el-col :span="4">
          <span>当前价</span>
        </el-col>
        <el-col :span="4">
          <span>最新出价方</span>
        </el-col>
          <el-col :span="2">
            <el-button
                v-if="auctionEnrolmentDetails.offerFirmId"
                @click.native.prevent="viewFiveDetail()"
                type="waring"
                size="small">
                查看
              </el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span><div class="grid-content">{{auctionEnrolmentDetails.commodity}}</div></span>
        </el-col>
        <el-col :span="4">
          <span><div class="grid-content">{{auctionEnrolmentGetter.name}}</div></span>
        </el-col>
        <el-col :span="3">
          <span><div class="grid-content">{{auctionEnrolmentGetter.quantity}}</div></span>
        </el-col>
        <el-col :span="3">
          <span><div class="grid-content">{{auctionEnrolmentGetter.hangWeight}}{{auctionEnrolmentGetter.unit}}</div></span>
        </el-col>
        <el-col :span="4">
          <span><div class="grid-content">{{auctionEnrolmentDetails.offerPrice}}</div></span>
        </el-col>
        <el-col :span="4" >
          <span><div class="grid-content">{{ auctionEnrolmentDetails.offerFirmId }}</div></span>
        </el-col>
        <el-col :span="2">
         <el-button
             style="margin-top:5px;"  
            @click.native.prevent="paranm_queryAuctionEnrolmentDetails()"
            type="danger"
            size="small">
            刷新
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="form" :rules="rules" :model="form" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="减价幅度" prop="addPrice">
              <el-select class="width-100" v-model="form.addPrice" placeholder="选择减价幅度">
                <el-option
                  v-for="item in auctionRule"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12" v-if="auctionEnrolmentDetails.tradeWay=='P'">
            <el-form-item label="摘单重量" prop="minPickingWeight">
              <el-input v-model="form.minPickingWeight" :placeholder="'最小摘单重量是'+auctionEnrolmentDetails.minPickingWeight+auctionEnrolmentGetter.unit"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="我的报价" prop="price">
              <el-input v-model="form.price"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm()" :loading="isAddLoading">竞 价</el-button>
    </span>
  </el-dialog>
   <el-dialog
   v-dialogDrag
    title="出价记录"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible1"
    width="30%"
   >
      <el-row style="text-align:center;">
          <el-col :span="12" style="font-size:16px">
            出价方
          </el-col>
          <el-col :span="12" style="font-size:16px">
            出价价格
          </el-col>  
      </el-row>
      <!-- <div v-if="fiveDetails.length!=0"> -->
          <el-row v-for="(item,index) of fiveDetails" :key="index"  style="text-align:center;">
          <el-col :span="12">
            {{ item.offerFirmId }}
          </el-col>
          <el-col :span="12">
            {{ item.offerPrice }}
          </el-col> 
      </el-row>
      <!-- </div> -->

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible1=false">取 消</el-button>
      <el-button type="primary"  @click="dialogVisible1=false">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    var checkPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('报价不能为空'));
      }
      // if(this.auctionEnrolmentDetails.tradeWay=='P'){
      //   let newPrice = this.auctionEnrolmentDetails.offerPrice
      //   if(this.auctionEnrolmentGetter.style==3){
      //     return callback()
      //   }
      //   if (value >= Number(newPrice)) {
      //     return callback(new Error('报价请低于当前价'))
      //   }else{
      //     return callback()
      //   }
      // }else{
      let newPrice = this.auctionEnrolmentDetails.offerPrice?this.auctionEnrolmentDetails.offerPrice:this.auctionEnrolmentGetter.startPrice
      if (value >= Number(newPrice)) {
        return callback(new Error('报价请低于当前价'))
      } else {
        let num = Number(newPrice) - Number(value)
        let isRight = false
        for (let i = 0; i < this.auctionRule.length; i++) {
          const element = this.auctionRule[i];
          if(num%Number(element)==0){
            isRight = true
          }
        }
        if (isRight) {
          return callback()
        } else {
          return callback(new Error('报价不符合减价幅度整数倍要求'))
        }
         }
      // }
    };
    var checkPickWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (this.auctionEnrolmentDetails.tradeWay=='P' && !value) {
        return callback(new Error('摘单重量不能为空'));
      } else if(value){
        if (!reg.test(value) || Number(value) <= 0) {
          return callback(new Error("最小摘单重量必须是正数且不能超过两位小数"));
        } else if (Number(value) >= Number(this.auctionEnrolmentDetails.hangWeight)) {
          return callback(new Error("摘单重量必须是小于挂单重量"));
        } else if (Number(value) < Number(this.auctionEnrolmentDetails.minPickingWeight)) {
          return callback(new Error("摘单重量必须是大于等于最小摘单重量"));
        }else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      dialogVisible1:false,
      form: {
        addPrice:'',// 加价幅度
        price:'', //我的报价
        // minPickingWeight: ''// 摘单重量
      },
      fiveDetails: [],
      rules: {
        addPrice: [
          { required: false, message: "请选择加价幅度", trigger: "change" }
        ],
        price: [
          { required: true, message: "请输入我的报价", trigger: "change" },
          { validator: checkPrice, trigger: 'blur' }
        ],// 我的报价
        // minPickingWeight: [
        //   { required: false, message: "请输入摘单重量", trigger: "change" },
        //   { validator: checkPickWeight, trigger: 'change' }
        // ],// 摘单重量
      },
      isAddLoading: false,
      auctionEnrolmentDetails:{},
      auctionRule:[],
      // 倒计时
      timeOut:null,
      day:0,
      hour:'00',
      min:'00',
      second:'00'
    };
  },
  computed:{
    ...mapGetters(['auctionEnrolmentGetter']), // 列表数据
    finPrice(){
      return this.form.addPrice
    }
  },
  watch: {
    finPrice(val,oldVal){
      let newPrice = this.auctionEnrolmentDetails.offerPrice?this.auctionEnrolmentDetails.offerPrice:this.auctionEnrolmentGetter.startPrice
      if(val){
        this.form.price = Number(newPrice) - Number(val)
      }
    },
    'auctionEnrolmentDetails.offerPrice': {
      handler(val, oldVal) {
      //let newPrice = this.auctionEnrolmentDetails.offerPrice?this.auctionEnrolmentDetails.offerPrice:this.auctionEnrolmentGetter.startPrice
        if(val){
          this.form.price = Number(val) - Number(this.form.addPrice)
        }
      }
    },
  },
  methods: {
    timeClick(){
      if (this.auctionEnrolmentGetter&&this.auctionEnrolmentGetter.endTime) {
        this.countTime(this.auctionEnrolmentGetter.endTime)
        this.timeOut = setInterval(() => {
          this.countTime(this.auctionEnrolmentGetter.endTime)
          if(this.day==0&&this.hour=='00'&&this.min=='00'&&this.second=='00'){
            clearInterval(this.timeOut)
            this.timeOut = null
          }
        }, 1000);
      }
    },
    countTime(endPayTime) {
      // 获取当前时间
      const date = new Date()
      const now = date.getTime()
      // 设置截止时间
      // const endDate = new Date(endPayTime) // this.curStartTime需要倒计时的日期
      // const end = endDate.getTime()
      const end = Number(endPayTime)
      // 时间差
      const leftTime = end - now
      let day = ''
      let hour = ''
      let min = ''
      let second = ''
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        // 时
        let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
        hour = h < 10 ? '0' + h : h
        // 分
        let m = Math.floor(leftTime / 1000 / 60 % 60)
        min = m < 10 ? '0' + m : m
        // 秒
        let s = Math.floor(leftTime / 1000 % 60)
        second = s < 10 ? '0' + s : s
      } else {
        day = 0
        hour = '00'
        min = '00'
        second = '00'
      }
      this.day = day
      this.hour = hour
      this.min = min
      this.second = second
    },
    showDialog(row) {
      this.dialogVisible = true;
      this.paranm_queryAuctionEnrolmentDetails()
      // this.param_queryAuctionRule()
      this.timeClick()
    },
    // 查询五次报价
    viewFiveDetail(){
      protocolFwd.paranm_queryAuctionEnrolmentFiveDetails.param = { hangResourcesId : this.auctionEnrolmentGetter.hangResourcesId }
      http.postFront(protocolFwd.paranm_queryAuctionEnrolmentFiveDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.fiveDetails = value.content
          this.dialogVisible1 = true
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    // 详情
    paranm_queryAuctionEnrolmentDetails() {
      protocolFwd.paranm_queryAuctionEnrolmentDetails.param = { hangResourcesId : this.auctionEnrolmentGetter.hangResourcesId }
      http.postFront(protocolFwd.paranm_queryAuctionEnrolmentDetails).then(response => {
        const { code, message, value } = response.data;
        console.log(value)
        if (code == 0) {
          // if(value.tradeWay=='P'){
          //     value.offerPrice = util.floatDiv(value.startPrice, value.hangWeight)
          //   }
          this.auctionEnrolmentDetails = value
          console.log(this.auctionEnrolmentDetails)
          this.initAddPrice()
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    }, 
    // // 加价幅度
    initAddPrice() {
      let arr = []
      let arrNum = 20
      for (let i = 0; i < arrNum; i++) {
        arr[i] = this.auctionEnrolmentDetails.addPrice*(i+1)
      }
      this.auctionRule = arr
    },
    // param_queryAuctionRule() {
    //   protocolFwd.param_queryAuctionRule.param = { commodityTypeId : this.auctionEnrolmentGetter.commodityTypeId }
    //   http.postFront(protocolFwd.param_queryAuctionRule).then(response => {
    //     const { code, message, value } = response.data;
    //     console.log(value)
    //     if (code == 0) {
    //       let arr = []
    //       arr[0] = value.addPriceOne
    //       arr[1] = value.addPriceTwo
    //       arr[2] = value.addPriceThree
    //       arr[3] = value.addPriceFour
    //       arr[4] = value.addPriceFive
    //       this.auctionRule = arr
    //     } else {
    //       this.$EL_MESSAGE(message)
    //     }
    //   })
    // },
    confirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolNJP.param_offer.param.itemId = Number(this.auctionEnrolmentGetter.hangResourcesId);
          protocolNJP.param_offer.param.offerPrice = this.form.price+'';
          // protocolNJP.param_offer.param.weight = this.form.minPickingWeight+'';
          this.isAddLoading = true;
          http
            .postNewJP(protocolNJP.param_offer)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                // this.dialogVisible = false;
                this.$EL_MESSAGE(message);
                this.paranm_queryAuctionEnrolmentDetails()
                // this.$refs["form"].resetFields();
                // this.$emit("reLoad");
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        addPrice:'',// 加价幅度
        price:'', //我的报价
      };
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.dialogVisible = false;
      if(this.timeOut){
        clearInterval(this.timeOut)
        this.timeOut = null
      }
    }
  }
};
</script>
<style scoped>
.grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
</style>